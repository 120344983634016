/* Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 0 15px;
  width: 100%;
}

.login-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.login-form {
  border: 1px solid #ccc;
  padding: 20px;
  max-width: 300px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

label {
  font-size: 14px;
  margin-bottom: 6px;
}

input {
  width: auto;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 16px;
}

button {
  background-color: #0c5793;
  color: #fff;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  max-width: max-content;
  margin: 0 0 0 auto;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-top: 8px;
}
