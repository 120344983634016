/* dashboard styling */
  .secWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    width: 100%;
  }
  .secWrapper h1 {
    margin: 0 auto 30px 0;
    padding: 0 23px;
  }
  .secWrapper .contentWrapper {
    position: relative;
    justify-content: space-between;
    padding: 0 15px;
  }
  .secWrapper .contentWrapper::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background: #ccc;
    top: 0;
    left: 46%;
    transform: translateX(-46%);
  }
  .itemWrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    flex-wrap: wrap;
  }
  .configurationWrapper {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
  }
  .configurationWrapper h3 {
    text-align: center;
    margin-top: 0;
  }
  /* .secWrapper .contentWrapper .configurationWrapper .configurationItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  } */
  .itemBox {
    padding: 16px 16px 25px 16px;
    border: 1px solid #ccc;
    margin: 0 8px 15px 8px;
    border-radius: 8px;
    background-color: #dee8f3;
  }
  .itemBox.clickable {
    cursor: pointer;
  }
  .itemBox.sty1 {
    border: none;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #dee8f3;
  }
  .itemBox.sty1 .contentWrap {
    margin-top: 10px;
    display: flex;
    position: relative;
  }
  .itemBox.sty1 .contentWrap .error-message {
    position: absolute;
    left: 0;
    bottom: -17px;
  } 
  .itemBox.sty1 label
   {
    margin: 0 20px 20px 0;
  }
  .itemBox.sty1 input {
    padding: 7px;
  }
  .itemBox.sty1.syncPill {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .itemBox.sty1.syncPill .btnPrimary {
    margin: 0 auto 20px auto;
  }
  .itemBox.sty1 .btnPrimary {
    margin-left: 20px;
  }
  .itemBox.sty1 .ageField {
    max-width: 300px;
    width: 100%;
  }
  .btnPrimary {
    background-color: #0C5793 !important;
    color: #fff !important;
    max-height: 40px;
  }
  .btnPrimary:hover {
    background-color: #3a75b5 !important;
  }
  .btnPrimary:disabled {
    background-color: #ccc !important;
  }
  .btnPrimary.active {
    background-color: #3a75b5 !important;
  }

.inputWrapper {
  width: 800px;
}
.searchInput {
  width: 100%;
}
.logHeader {
  background-color: #007bff;
}
.navTabs a.active {
  background-color: #3a75b5;
  transition: all 0.35s ease;
}

.dataWrapperLog {
  height: calc(100vh - 64px);
  width: 100%;
}

.custom-row-class:hover {
  background-color: #dee8f3 !important;
}
.custom-row-class .MuiDataGrid-cell {
  border-right: 1px solid #ccc;
  /* max-width: 250px;
  width: 100%; */
}
.custom-row-class .MuiDataGrid-cell:last-child {
  border-right: none;
}

/* @media (max-width: 1300px) {
  .secWrapper .contentWrapper::after {
    left: 52%;
    transform: translateX(-52%);
  }
} */

@media (max-width: 1200px) {
  .itemBox.sty1.btnWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .itemBox.sty1.btnWrap .btnPrimary {
    margin-bottom: 20px;
  }
  .secWrapper .contentWrapper::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .itemWrapper {
    justify-content: flex-start;
  }
}
@media (max-width: 900px) {
  .secWrapper .contentWrapper::after {
    display: none;
  }
  @media (max-width: 767px) {
    .navTabs {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    .itemBox.sty1 {
      max-width: 90%;
      flex: 0 0 90%;
    }
  }
}

.nav-element {
  color: #dee8f3;
  text-decoration: none;
  margin-left: "3px";
}
